.ant-layout-content {
  background: #fff;
}
.left-side-tree {
  height: 100vh;
  overflow: auto;
}
.main-content {
  height: calc(100vh - 92px);
  overflow: auto;
  border-radius: 8px;
}
.ant-layout-footer {
  padding: 5px 50px;
}
.site-layout-background-header {
  height: 40px;
  margin: 10px 16px;
  background: #fff;
  border-radius: 8px;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none
  }
}
.site-layout-header {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-layout-header {
  padding: 0 17px;
}
.header-select-style {
  width: 200px;
}
.menu-icon {
  width: 16px;
  height: 16px;
}
.system-mange-wrapper-loading {
  width: 100%;
  top: 90px;
  right: 0;
  bottom: 70px;
  left: 0;
  background: #fff;
  height: calc(100vh - 122px);
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -40px;
  top: 50%;
  margin-top: -40px;
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 80px;
  height: 80px;
  border:  solid 4px transparent;
  border-top-color:  #1890ff !important;
  border-left-color: #1890ff !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
0%   { transform: rotate(0deg);   transform: rotate(0deg); }
100% { transform: rotate(360deg); transform: rotate(360deg); }
}