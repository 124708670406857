/* @import '~antd/dist/antd.css'; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道
  内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(24, 144, 255, .3);
  border-radius: 17px;
  background-color: #f5f5f5;
}

/*定义滑块
  内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 15px;
  box-shadow: inset 0 0 6px rgba(24, 144, 255, .3);
  background-color: #1890ff;
}

.content-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-weight: bolder;

  .content-title-icon {
    display: inline-block;
    width: 5px;
    height: 20px;
    margin-right: 4px;
    background: var(--ik-color-primary);
  }

  .content-title-prompt {
    font-size: var(--ik-font-size-4);
    color: var(--ik-color-danger);
    font-weight: normal;
  }
}

.form-dash-hr {
  border: none;
  border-top: 0.5px solid #555;
  width: 100%
}

.dsp-flex {
  display: flex;
}

